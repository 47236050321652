import "./index.scss";
import background1 from "../../resources/images/background-plain.png";
import Navbar from "../../components/navbar";
import DialogBox from "../../components/dialogBox";
import { Col, Container, Row, Table } from "react-bootstrap";
import PlayerDetails from "../../components/playerDetails/mobile";
import PlayerBid from "../../components/playerBid/mobile";
import { useDispatch, useSelector } from "react-redux";
import { selectShowLoading } from "../../store/features/auctionSlice";
import RoundButton from "../../components/roundButton";
import BiddingPrice from "../../components/biddingPrice";

import gavel from "../../resources/images/gavel.png";
import money from "../../resources/images/money.png";
import gavel2 from "../../resources/images/gavel2.png";
import airplane from "../../resources/images/airplane.png";
import depressed from "../../resources/images/depressed.png";
import indianFlag from "../../resources/logos/indianFlag.png";
import allRounder from "../../resources/logos/allrounder.png";
import Batsmen from "../../resources/logos/batsmen.png";
import Bowler from "../../resources/logos/bowler.png";
import Uncapped from "../../resources/logos/uncapped.png";
import wk from "../../resources/logos/wicketkeeper.png";

import FlightTakeoffSharpIcon from "@mui/icons-material/FlightTakeoffSharp";

import {
  activeBidding,
  bid,
  boughtPlayers,
  getActiveSession,
  getProfile,
  purseremaining as getPurse,
  selectactiveBidding,
  selectActiveSession,
  selectBidderProfile,
  selectBoughtPlayerData,
  selectFilteredPlayers,
  selectpurseremaining,
  selectstatus,
  blockStatus,
  selectBlockStatus,
  lastYearPlayer,
  selectLastYearPlayersData,
  rtm,
  selectLoginStatus,
} from "../../store/features/bidderSlice";
import { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import Modal from "../../components/modal/mobile";
import { CircularProgress } from "@mui/material";
import { CleaningServices } from "@mui/icons-material";

const BiddingPage = ({ socket }) => {
  let [counter, setCounter] = useState(1);
  let [RTM, setRTM] = useState(false);
  let [bidSetting, setBidSetting] = useState([5, 10]);
  let [enterAuction, setEnterAuction] = useState(false);
  let [bidAtBase, setBidAtBase] = useState(false);
  let [content, setContent] = useState("");
  let [lastBidBy, setLastBidBy] = useState({ team: "", currentBid: 0 });
  let [blockBidding, setBlockBidding] = useState(false);
  let [stopBidding, setStopBidding] = useState(false);
  let [disabled, setDisabled] = useState(false);
  let [showLoading, setShowLoading] = useState(false);
  let [activeBid, setActiveBid] = useState();
  let LoginStatus = useSelector(selectLoginStatus);
  let profile = useSelector(selectBidderProfile);
  let session = useSelector(selectActiveSession);
  let activePlayer = useSelector(selectactiveBidding);
  let purseremaining = useSelector(selectpurseremaining);
  let playersData = useSelector(selectBoughtPlayerData);
  let lastYearData = useSelector(selectLastYearPlayersData);
  let filteredPlayerData = useSelector(selectFilteredPlayers);
  let blockActiveStatus = useSelector(selectBlockStatus);
  let status = useSelector(selectstatus);
  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProfile());
    dispatch(activeBidding());
    dispatch(getActiveSession());
    dispatch(getPurse());
    dispatch(lastYearPlayer());
    dispatch(boughtPlayers());
    dispatch(blockStatus());
  }, [counter]);

  useEffect(() => {
    if (activePlayer?.lastBidBy?.team) {
      setLastBidBy((prev) => ({
        ...prev,
        team: activePlayer?.lastBidBy?.team,
        currentBid: activePlayer?.currentBid,
      }));
    }
  }, [activePlayer]);

  useEffect(() => {
    if (lastYearData?.LastYearPlayer?.[0]) {
      let match = lastYearData?.LastYearPlayer?.find(
        (p) => activePlayer?._id?.toString() === p
      );

      if (match) {
        setRTM(true);
      } else {
        setRTM(false);
      }
    } else {
      setRTM(false);
    }
  }, [activePlayer, lastYearData]);

  useEffect(() => {
    if (profile?._id && session?.sessionId) {
      socket.emit("bidder-entry", profile._id);
      socket.on("bidder-connected", () => {
        // console.log("connected");
        socket.emit("join-bidder-session", session?.sessionId);
      });
    }

    setBlockBidding(profile?.bidBlockStatus);
    setStopBidding(session?.biddingStopped);
  }, [profile?._id, profile?.blockBidStatus, session?.sessionId]);

  useEffect(() => {
    if (activePlayer?.name) {
      if (activePlayer?.currentBid > 0) {
        setBidAtBase(false);
        if (activePlayer?.currentBid < 100) {
          setBidSetting([10, 20, 50]);
          if (!Boolean(activeBid)) {
            setActiveBid(bidSetting?.[0]);
          }
        } else if (activePlayer?.currentBid < 300) {
          setBidSetting([20, 50]);
          if (!Boolean(activeBid)) {
            setActiveBid(bidSetting?.[0]);
          }
        } else if (activePlayer?.currentBid > 300) {
          setBidSetting([20, 50]);
          if (!Boolean(activeBid)) {
            setActiveBid(bidSetting?.[0]);
          }
        } else {
          setBidSetting([20, 50]);
        }
      } else {
        let base = activePlayer?.basePrice;
        setBidAtBase(true);
        setBidSetting([base]);
      }
    } else {
      setBidSetting([]);
    }
  }, [activePlayer?.currentBid, activePlayer]);

  useEffect(() => {
    socket.on("block-started", (playerType) => {
      setContent(playerType?.toUpperCase() + " BLOCK STARTED!");
      setShowLoading(true);
      setCounter((a) => a + 1);
      setTimeout(() => {
        setShowLoading(false);
      }, 2500);
    });

    // setContent(
    //   <>
    //     <div style={{ fontSize: "1.7rem", textTransform: "uppercase" }}>
    //       Bidding Stopped
    //     </div>
    //     <RoundButton
    //       className="round-btn-rtm"
    //       style={{
    //         "&:hover": {
    //           color: "#fff",
    //         },
    //         marginTop: "1rem",
    //         padding: ".5rem 1.2rem",
    //         fontSize: "1rem",
    //         minWidth: "fit-content",
    //         textTransform: "uppercase",
    //       }}
    //     >
    //       use RTM
    //     </RoundButton>
    //   </>
    // );
    // setShowLoading(true);

    socket.on("block-paused", () => {
      setContent(
        <>
          <span>Bidding Stopped</span>
        </>
      );
      setShowLoading(true);
      setCounter((a) => a + 1);
      setTimeout(() => {
        setShowLoading(false);
      }, 2500);
    });

    socket.on("block-finished", (playerType) => {
      setShowLoading(true);
      //  // console.log(playerType);
      setContent(playerType?.toUpperCase() + " BLOCK FINISHED!");
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    });

    socket.on("set-bid-reset", () => {
      setContent(
        <>
          {/* <img src={depressed} style={{ height: "13rem" }} /> */}
          <span
            style={{
              color: "inhe",
              fontSize: "2rem",
            }}
          >
            {" "}
            UPDATING DATA...
          </span>
        </>
      );

      setShowLoading(true);

      // setContent();
      setTimeout(() => {
        window.location.reload();
      }, 1000);
      setCounter((a) => a + 1);
    });

    socket.on("bid-updated", (playerData) => {
      //  // console.log(playerData);
      setBlockBidding(false);
      setBidAtBase(false);
      setActiveBid(0);
      setCounter((a) => a + 1);

      if (playerData?.data) {
        setShowLoading(true);
        setContent(
          <>
            <img src={gavel} style={{ height: "10rem" }} />
            <div
              style={{
                fontSize: "2rem",
                color: "#0BD18A",
              }}
            >
              Next Player
            </div>
            <div>{playerData?.data?.name}</div>
          </>
        );
        setTimeout(() => {
          setShowLoading(false);
          // window.location.reload();
        }, 2000);
      }
    });

    socket.on("rtm-updated", (playerData) => {
      //  // console.log(playerData);
      setBlockBidding(true);
      dispatch(getActiveSession());

      if (playerData?.data) {
        setShowLoading(true);
        setContent(
          <>
            <img src={gavel} style={{ height: "10rem" }} />
            <div
              style={{
                fontSize: "2rem",
                color: "#0BD18A",
              }}
            >
              Next Player
            </div>
            <div>{playerData?.data?.name}</div>
          </>
        );
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
      setCounter((a) => a + 1);
    });

    socket.on("set-player-unsold", () => {
      setContent(
        <>
          <img src={depressed} style={{ height: "13rem" }} />
          <span
            style={{
              color: "#920202",
              fontSize: "2rem",
            }}
          >
            {" "}
            UNSOLD
          </span>
        </>
      );
      setShowLoading(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
      setCounter((a) => a + 1);
    });

    console.log(profile?.Franchise?._id);
    socket.on("set-player-sold", (player) => {
      setCounter((a) => a + 1);
      dispatch(activeBidding());
      setShowLoading(true);
      setContent(
        <>
          <img src={gavel} style={{ height: "10rem" }} />
          <div
            style={{
              fontSize: "2rem",
              color:
                player?.currentBid === profile?.activeBidding?.lastBid &&
                player?.lastBidBy?.Franchise === profile?.Franchise?._id
                  ? "#0BD18A"
                  : "#920202",
            }}
          >
            SOLD
          </div>
          <div>{player?.lastBidBy?.team}</div>
        </>
      );
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    });

    socket.on("stop-bid", () => {
      //  // console.log("svibi");
      setShowLoading(true);
      setContent(
        <>
          <img src={gavel} style={{ height: "10rem" }} />
          <div
            style={{
              fontSize: "2rem",
              color:
                activePlayer?.currentBid === profile?.activeBidding?.lastBid
                  ? "#0BD18A"
                  : "#920202",
            }}
          >
            BIDDING STOPPED!
          </div>
        </>
      );
      setCounter((a) => a + 1);
      dispatch(getActiveSession());

      setBlockBidding(true);
      setStopBidding(true);
      setCounter((a) => a + 1);
      setTimeout(() => {
        setShowLoading(false);
        // window.location.reload();
      }, 2000);
    });

    socket.on("resume-bid", () => {
      setShowLoading(true);
      setContent(
        <>
          <img src={gavel} style={{ height: "10rem" }} />
          <div
            style={{
              fontSize: "2rem",
              color: "#0BD18A",
            }}
          >
            BIDDING RESUMED!
          </div>
        </>
      );
      dispatch(getActiveSession());
      setBlockBidding(false);

      setBlockBidding(false);
      setStopBidding(false);
      setCounter((a) => a + 1);
      setTimeout(() => {
        setShowLoading(false);
        // window.location.reload();
      }, 2000);
    });
  }, [session?.sessionId]);

  const onBidClick = async () => {
    if (session?.sessionId) {
      socket.emit("check-bid-status", session?.sessionId);
      setBlockBidding(true);
      let name = activePlayer?.name?.split(" ");
      let initial = name && name[0][0];
      let lastName = name?.slice(1, name.length)?.join(" ");
      let bidValue = activeBid
        ? activePlayer?.currentBid + activeBid
        : activePlayer?.basePrice;

      let bidIncreaseValue = activeBid ? activeBid : activePlayer?.basePrice;

      // For A loading time wait
      setDisabled(true);
      setContent(
        <>
          <div style={{ fontFamily: "Poppins-Regular" }}>BID QUEUED!</div>
          <div style={{ fontSize: "1.5rem" }}>
            {initial ? `${initial}. ${String(lastName)}` : "-"}
          </div>
          <div style={{ fontSize: "1.3rem", color: "#0BD18A" }}>
            {bidIncreaseValue > 100
              ? `${bidIncreaseValue / 100}CR+`
              : `${bidIncreaseValue}L+`}
          </div>
        </>
      );
      // For A loading time wait

      if (bidValue < purseremaining?.value) {
        let res = await dispatch(
          bid({
            base: bidAtBase,
            value: Number(activeBid),
          })
        );
        if (res?.payload?.statusCode === 200) {
          console.log(res?.payload?.data?.currentBid);
          let latestCurrentBid = res?.payload?.data?.currentBid;
          setDisabled(true);

          setContent(
            <>
              <div style={{ fontFamily: "Poppins-Regular" }}>
                BID POSTED AT!
              </div>
              <div style={{ fontSize: "1.5rem" }}>
                {initial ? `${initial}. ${String(lastName)}` : "-"}
              </div>
              <div style={{ fontSize: "1.3rem", color: "#0BD18A" }}>
                {latestCurrentBid > 100
                  ? `${latestCurrentBid / 100}CR`
                  : `${latestCurrentBid}L`}
              </div>
            </>
          );
          let res2 = await dispatch(activeBidding());
          if (res2?.payload?.statusCode === 200) {
            socket.emit("increase-bid", session?.sessionId);
          }
          setTimeout(() => {
            setDisabled(false);
          }, 5000);
        } else if (res?.payload?.statusCode === 304) {
          setDisabled(true);

          setContent(
            <>
              <div style={{ fontFamily: "Poppins-Regular" }}>
                BID NOT POSSIBLE
              </div>
              <div
                style={{
                  fontSize: "1.5rem",
                  color: "#920202",
                  textTransform: "uppercase",
                }}
              >
                {res?.payload?.msg}
              </div>
            </>
          );
          setTimeout(() => {
            setDisabled(false);
          }, 2000);
          await dispatch(getProfile());
        }
      } else {
        setContent(
          <>
            <div style={{ fontFamily: "Poppins-Regular" }}>
              BID NOT POSSIBLE
            </div>
            <div style={{ fontSize: "1.5rem", color: "#920202" }}>
              INSUFFICIENT FUNDS
            </div>
            <div style={{ fontSize: "1.3rem" }}>HATT BIKHARI</div>
          </>
        );
        setDisabled(true);
        setTimeout(() => {
          setDisabled(false);
        }, 2000);
      }
      setCounter((a) => a + 1);
    }
  };

  const onRTMClick = async () => {
    if (session?.sessionId) {
      socket.emit("check-rtm-status", session?.sessionId);
      let name = activePlayer?.name?.split(" ");
      let initial = name && name[0][0];
      let lastName = name?.slice(1, name.length)?.join(" ");

      let bidValue = activePlayer?.currentBid;

      if (bidValue < purseremaining?.value) {
        setContent(
          <>
            <div style={{ fontFamily: "Poppins-Regular" }}>BID POSTED!</div>
            <div style={{ fontSize: "1.5rem" }}>
              {initial ? `${initial}. ${String(lastName)}` : "-"}
            </div>
            <div style={{ fontSize: "1.3rem", color: "#0BD18A" }}>
              {bidValue > 100 ? `${bidValue / 100}CR` : `${bidValue}L`}
            </div>
          </>
        );
        setDisabled(true);
        let res = await dispatch(
          rtm({
            value: Number(activeBid),
          })
        );
        if (res?.payload?.statusCode === 200) {
          let res2 = await dispatch(activeBidding());
          if (res2?.payload?.statusCode === 200) {
            socket.emit("apply-rtm", session?.sessionId);
          }
        }

        setTimeout(() => {
          setDisabled(false);
        }, 1000);
      } else {
        setContent(
          <>
            <div style={{ fontFamily: "Poppins-Regular" }}>
              BID NOT POSSIBLE
            </div>
            <div style={{ fontSize: "1.5rem", color: "#920202" }}>
              INSUFFICIENT FUNDS
            </div>
            <div style={{ fontSize: "1.3rem" }}>HATT BIKHARI</div>
          </>
        );
        setDisabled(true);
        setTimeout(() => {
          setDisabled(false);
        }, 2000);
      }
      setCounter((a) => a + 1);
    }
  };

  //  // console.log(activePlayer);
  return (
    <>
      {LoginStatus === "waiting" && (
        <>
          {
            <CircularProgress
              sx={{
                ".MuiCircularProgress-svg": {
                  // height: "6rem",
                  // width: "6rem",
                },
                position: "absolute",
                top: "40%",
                left: "47%",
                height: "6rem !important",
                width: "6rem !important",
              }}
            />
          }
        </>
      )}

      {LoginStatus === "failed" && (
        <>
          Not Authenticated. <Link to="/bidder/login">Login</Link>
          <Navigate to="/bidder/login" replace></Navigate>
        </>
      )}

      {LoginStatus === "success" && (
        <>
          <div
            className="main"
            style={{
              overflow: "hidden",
              background: `url(${background1}) center center/cover`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center top",
              // backgroundSize: "90rem",
              minHeight: "100vh",
              position: "relative",
            }}
          >
            <Navbar mobile={true} profile={profile} />
            <Container className="user-container">
              <Modal
                setShow={setShowLoading}
                show={showLoading || disabled}
                isMobile={true}
                content={content}
              />

              {/* <img src={depressed} style={{ height: "10rem" }} />
                <span style={{ color: "#920202", fontSize: "1.5rem" }}>
                  {" "}
                  UNSOLD
                </span> */}
              {/* </Modal> */}
              {/* <Row
                className=""
                style={{ justifyContent: "center", width: "100%" }}
              >
                <div className="purse-remain">
                  <Row
                    className={`dialog-box`}
                    style={{
                      minWidth: "0",
                      maxWidth: "none",
                      width: "100%",
                    }}
                  >
                    <Col
                      className="box white header"
                      style={{
                        textAlign: "center",
                        justifyContent: "center",
                        fontFamily: "Poppins-Semibold",
                        fontSize: "0.9rem",
                        letterSpacing: "0.3px",
                        padding: 0,
                      }}
                    >
                      <span>RTM</span>
                    </Col>
                    <Col className="box blue">
                      <span
                        style={{
                          fontSize: "1rem",
                          padding: 0,
                          letterSpacing: "0.5px",
                        }}
                      >
                        2
                      </span>
                    </Col>
                    <Col
                      className="box white header"
                      style={{
                        textAlign: "center",
                        justifyContent: "center",
                        fontFamily: "Poppins-Semibold",
                        fontSize: "0.9rem",
                        letterSpacing: "0.3px",
                        padding: 0,
                      }}
                    >
                      <span>Purse </span>
                    </Col>
                    <Col className="box blue">
                      <span
                        style={{
                          fontSize: "1rem",
                          padding: 0,
                          letterSpacing: "0.5px",
                        }}
                      >
                        {purseremaining?.value
                          ? purseremaining?.value > 99
                            ? `${purseremaining.value / 100} CR  `
                            : `${purseremaining.value} L `
                          : "55.5 CR "}
                      </span>
                    </Col>
                  </Row>
                </div>
              </Row> */}
              <Row
                style={{
                  paddingTop: "10%",
                  marginTop: "1rem",
                  justifyContent: "space-between",
                  width: "85%",
                }}
              >
                <Col xs={7}>
                  <Row
                    style={
                      {
                        // backgroundColor: "#0040A8",
                      }
                    }
                  >
                    <Col className="team-details team-details-1">
                      <DialogBox
                        wrapperStyle={{ minWidth: 0 }}
                        header="Criteria"
                        className="player-tables-1"
                        headerStyle={{
                          fontFamily: "Poppins-Regular",
                          // paddingLeft: "2rem",
                          textAlign: "center",
                          fontSize: "0.9rem",
                        }}
                      >
                        <Table
                          size="sm"
                          style={{
                            width: "75%",
                            color: "#F9F8F8",
                            marginBottom: "0.5rem",
                          }}
                        >
                          <tbody>
                            <tr>
                              <td
                                style={{
                                  textAlign: "left",
                                  border: "none",
                                  fontSize: "0.7rem",
                                  fontFamily: "Poppins-Regular",
                                  verticalAlign: "middle",
                                }}
                              >
                                <img
                                  alt="overseas"
                                  style={{
                                    marginLeft: ".2rem",
                                    // mixBlendMode: "plus-lighter",
                                    height: "1.3rem",
                                  }}
                                  src={airplane}
                                />
                                {/* <FlightTakeoffSharpIcon /> */}
                              </td>
                              <td
                                className="text-center"
                                style={{
                                  border: "none",
                                  fontSize: "0.8rem",
                                  fontFamily: "Poppins-Regular",
                                }}
                              >
                                {filteredPlayerData?.Overseas
                                  ? filteredPlayerData.Overseas.length
                                  : 0}
                                /8
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  textAlign: "left",
                                  border: "none",
                                  fontSize: "0.7rem",
                                  fontFamily: "Poppins-Regular",
                                  verticalAlign: "middle",
                                }}
                              >
                                <img
                                  alt="overseas"
                                  style={{
                                    height: "1.3rem",
                                  }}
                                  src={indianFlag}
                                />
                              </td>
                              <td
                                className="text-center"
                                style={{
                                  border: "none",
                                  fontSize: "0.8rem",
                                  fontFamily: "Poppins-Regular",
                                }}
                              >
                                {filteredPlayerData?.Indians
                                  ? filteredPlayerData.Indians.length
                                  : 0}
                              </td>
                            </tr>
                            {/* <tr>
                              <td
                                style={{
                                  textAlign: "left",
                                  border: "none",
                                  fontSize: "0.7rem",
                                  fontFamily: "Poppins-Regular",
                                  verticalAlign: "middle",
                                }}
                              >
                                <img
                                  alt="overseas"
                                  style={{
                                    height: "1.3rem",
                                  }}
                                  src={Uncapped}
                                />
                              </td>
                              <td
                                className="text-center"
                                style={{
                                  border: "none",
                                  fontSize: "0.8rem",
                                  fontFamily: "Poppins-Regular",
                                }}
                              >
                                {filteredPlayerData?.Uncapped
                                  ? filteredPlayerData.Uncapped.length
                                  : 0}
                                /1
                              </td>
                            </tr> */}
                          </tbody>
                        </Table>
                      </DialogBox>
                    </Col>
                    <Col className="team-details team-details-2">
                      <DialogBox
                        wrapperStyle={{ minWidth: 0 }}
                        header={
                          <span>
                            {`${
                              playersData?.players?.[0]
                                ? playersData?.players?.length
                                : 0
                            }/25`}
                          </span>
                        }
                        className="player-tables-2"
                        headerStyle={{
                          fontFamily: "Poppins-Regular",
                          // paddingRight: "2rem",
                          textAlign: "center",
                          fontSize: "0.9rem",
                        }}
                      >
                        <Table
                          size="sm"
                          style={{
                            width: "75%",
                            color: "#F9F8F8",
                            marginBottom: "0.5rem",
                          }}
                        >
                          <tbody>
                            <tr>
                              <td
                                style={{
                                  textAlign: "left",
                                  border: "none",
                                  fontSize: "0.7rem",
                                  fontFamily: "Poppins-Regular",
                                  verticalAlign: "middle",
                                }}
                              >
                                <img
                                  alt="overseas"
                                  style={{
                                    height: "1.3rem",
                                  }}
                                  src={Batsmen}
                                />
                              </td>
                              <td
                                className="text-center"
                                style={{
                                  border: "none",
                                  fontSize: "0.8rem",
                                  fontFamily: "Poppins-Regular",
                                }}
                              >
                                {filteredPlayerData?.Batter
                                  ? filteredPlayerData.Batter.length
                                  : 0}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  textAlign: "left",
                                  border: "none",
                                  fontSize: "0.7rem",
                                  fontFamily: "Poppins-Regular",
                                  verticalAlign: "middle",
                                }}
                              >
                                <img
                                  alt="overseas"
                                  style={{
                                    height: "1.3rem",
                                  }}
                                  src={Bowler}
                                />
                              </td>
                              <td
                                className="text-center"
                                style={{
                                  border: "none",
                                  fontSize: "0.8rem",
                                  fontFamily: "Poppins-Regular",
                                }}
                              >
                                {filteredPlayerData?.Bowler
                                  ? filteredPlayerData.Bowler.length
                                  : 0}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  textAlign: "left",
                                  border: "none",
                                  fontSize: "0.7rem",
                                  fontFamily: "Poppins-Regular",
                                  verticalAlign: "middle",
                                }}
                              >
                                <img
                                  alt="overseas"
                                  style={{
                                    height: "1.3rem",
                                    mixBlendMode: "plus-lighter",
                                  }}
                                  src={wk}
                                />
                              </td>
                              <td
                                className="text-center"
                                style={{
                                  border: "none",
                                  fontSize: "0.8rem",
                                  fontFamily: "Poppins-Regular",
                                }}
                              >
                                {filteredPlayerData?.Wicketkeeper
                                  ? filteredPlayerData.Wicketkeeper.length
                                  : 0}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  textAlign: "left",
                                  border: "none",
                                  fontSize: "0.7rem",
                                  fontFamily: "Poppins-Regular",
                                  verticalAlign: "middle",
                                }}
                              >
                                <img
                                  alt="overseas"
                                  style={{
                                    height: "1.3rem",
                                  }}
                                  src={allRounder}
                                />
                              </td>
                              <td
                                className="text-center"
                                style={{
                                  border: "none",
                                  fontSize: "0.8rem",
                                  fontFamily: "Poppins-Regular",
                                }}
                              >
                                {filteredPlayerData["All Rounder"]
                                  ? filteredPlayerData["All Rounder"].length
                                  : 0}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </DialogBox>
                    </Col>
                  </Row>
                </Col>
                <Col xs={5} style={{}}>
                  <Row
                    className={`dialog-box`}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      minWidth: "0",
                      maxWidth: "none",
                      // width: "100%",
                    }}
                  >
                    <Col
                      style={{
                        marginBottom: "1rem",
                        display: "flex",
                        paddingRight: 0,
                        flexDirection: "row",
                      }}
                    >
                      <Col
                        className="box white header"
                        style={{
                          backgroundColor: RTM ? "rgb(21 166 128)" : "#f27059",
                          textAlign: "center",
                          color: "#FFF",
                          justifyContent: "center",
                          fontFamily: "Poppins-Semibold",
                          fontSize: "0.9rem",
                          letterSpacing: "0.3px",
                          padding: 0,
                        }}
                      >
                        {/* <span>RTM</span> */}

                        <span
                          style={{
                            textDecoration: "none",
                            color: "#FFF",
                            font: "inherit",
                          }}
                          // to="/bidder/teamview"
                        >
                          RTM
                          <span
                            style={{
                              height: ".6rem",
                              width: ".6rem",
                              marginLeft: ".5REM",
                              backgroundColor: RTM ? "#4AE0B9" : "#d62828",
                              borderRadius: "50%",
                              display: "inline-block",
                            }}
                          ></span>
                        </span>
                      </Col>
                      <Col className="box blue">
                        <span
                          style={{
                            fontSize: "1rem",
                            padding: 0,
                            letterSpacing: "0.5px",
                          }}
                        >
                          {profile?.Franchise?.rtm}
                        </span>
                      </Col>
                    </Col>
                    <Col
                      style={{
                        display: "flex",
                        maxHeight: ".5rem",
                        paddingRight: 0,
                        flexDirection: "column",
                      }}
                    >
                      <Col
                        className=""
                        style={{
                          textAlign: "center",
                          color: "#dcdcdc",
                          fontSize: "1.9rem",
                          justifyContent: "center",
                          fontFamily: "Poppins-Semibold",
                          letterSpacing: "0.3px",
                          padding: 0,
                          width: "100%",
                        }}
                      >
                        <img
                          alt="money"
                          src={money}
                          style={{
                            width: "60%",

                            // height: ,
                          }}
                        ></img>
                      </Col>
                      <Col
                        style={{
                          textShadow:
                            ".2px .2px 3px #4AE0B901, .5px .5px 40px #ccc",
                          textAlign: "center",
                          color:
                            purseremaining?.value > 45
                              ? "#4AE0B9"
                              : purseremaining?.value > 15
                              ? "#dcdcdc"
                              : "#eb5e28",
                          fontSize: "1.6rem",
                        }}
                        className=" blue"
                      >
                        <span
                          style={{
                            padding: 0,
                            letterSpacing: "0.5px",
                          }}
                        >
                          {purseremaining?.value
                            ? purseremaining?.value > 99
                              ? `${purseremaining.value / 100} CR  `
                              : `${purseremaining.value} L `
                            : "55.5 CR "}
                        </span>
                      </Col>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row
                className=""
                style={{
                  padding: "1rem 0 0.5rem 0",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                {/* <RoundButton
                  type="button"
                  className="start-btn"
                  style={{
                    backgroundColor: "#57cc99",
                    minWidth: "4.4rem",
                    width: "4rem",
                    borderColor: "#57cc99",
                    padding: "0.3rem .7rem",
                    fontSize: "0.8rem",
                    // marginRight: "10px",
                    borderRadius: "1.5rem",
                     }}
                >
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                      font: "inherit",
                    }}
                  //  to="/bidder/teamview"
                  >
                    RTM
                    <span
                      style={{
                        height: ".6rem",
                        width: ".6rem",
                        marginLeft: "5px",
                        backgroundColor: "#55a630",
                        borderRadius: "50%",
                        display: "inline-block",
                      }}
                    ></span>
                  </Link>
                </RoundButton> */}

                {/* <RoundButton
                  className="start-btn"
                  noButton
                  style={{
                    backgroundColor: "#f27059",
                    minWidth: "4.4rem",
                    width: "4rem",
                    borderColor: "#f27059",
                    padding: "0.3rem .7rem",
                    fontSize: "0.8rem",
                    // marginRight: "10px",
                    borderRadius: "1.5rem",
                  }}
                >
                  <span
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                      font: "inherit",
                    }}
                    // to="/bidder/teamview"
                  >
                    RTM
                    <span
                      style={{
                        height: ".6rem",
                        width: ".6rem",
                        marginLeft: "5px",
                        backgroundColor: "#d62828",
                        borderRadius: "50%",
                        display: "inline-block",
                      }}
                    ></span>
                  </span>
                </RoundButton> */}

                <RoundButton
                  type="button"
                  className="start-btn"
                  style={{
                    backgroundColor: "#0040A8",
                    width: "4rem",
                    minWidth: "6rem",
                    borderColor: "#0040A8",
                    padding: "0.4rem",
                    marginLeft: "10px",
                    fontSize: "0.8rem",
                    borderRadius: "2rem",
                  }}
                >
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                      font: "inherit",
                    }}
                    to="/bidder/teamview"
                  >
                    My Team
                  </Link>
                </RoundButton>
                <RoundButton
                  type="button"
                  className="start-btn"
                  style={{
                    backgroundColor: "#0040A8",
                    width: "4rem",
                    minWidth: "7.8rem",
                    borderColor: "#0040A8",
                    padding: "0.4rem",
                    marginLeft: "10px",
                    fontSize: "0.8rem",
                    borderRadius: "2rem",
                  }}
                >
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                      font: "inherit",
                    }}
                    to="/bidder/allplayers"
                  >
                    Auction Pool
                  </Link>
                </RoundButton>
              </Row>
              {blockActiveStatus === "started" &&
              session?.sessionId &&
              session?.active ? (
                <>
                  <Row
                    className=""
                    style={{
                      padding: " 0",
                      width: "100%",
                      justifyContent: "center",
                    }}
                  >
                    <Col
                      xs="5"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "flex-end",
                        alignContent: "flex-end",
                      }}
                    >
                      <PlayerBid
                        isMobile={true}
                        player={activePlayer}
                        owner={profile}
                        activeBid={profile.activeBidding}
                      />
                    </Col>
                    <Col
                      xs="7"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "flex-end",
                        alignContent: "flex-end",
                      }}
                    >
                      <PlayerDetails isMobile={true} player={activePlayer} />
                    </Col>
                  </Row>

                  {activePlayer?.currentBid ===
                    profile?.activeBidding?.lastBid &&
                  activePlayer._id === profile?.activeBidding?.player &&
                  activePlayer?.lastBidBy?.franchise?.toString() ===
                    profile?.Franchise?._id?.toString() ? (
                    <Row
                      className=""
                      style={{
                        padding: 0,
                        marginTop: "0.7rem",
                        width: "87%",
                        justifyContent: "center",
                      }}
                    >
                      <Col
                        xs="12"
                        style={{
                          padding: "1rem 0",
                          color: "#F9F8F8",
                          fontSize: "3rem",
                          marginTop: "3rem",
                          textAlign: "center",
                          fontFamily: "Poppins-Regular",
                        }}
                      >
                        Bid Placed!
                      </Col>
                    </Row>
                  ) : (
                    <>
                      <Row
                        className=""
                        style={{
                          padding: 0,
                          marginTop: "0.7rem",
                          width: "87%",
                          justifyContent: "center",
                        }}
                      >
                        {/* <Col
                          xs="12"
                          style={{
                            padding: "1rem 0",
                            color: "#F9F8F8",
                            textAlign: "center",
                            fontFamily: "Poppins-Regular",
                          }}
                        >
                          Select Bid Increment
                        </Col> */}
                        {RTM ? (
                          <>
                            {stopBidding ? (
                              <>
                                <Col
                                  xs="12"
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "flex-end",
                                    marginTop: "1.5rem",
                                    color:
                                      profile?.Franchise?.rtm > 0
                                        ? "#0BD18A"
                                        : "#dc2f02",
                                    fontSize: "2REM",
                                    fontFamily: "Poppins-Semibold",
                                    alignContent: "flex-end",
                                  }}
                                >
                                  {profile?.Franchise?.rtm > 0
                                    ? "AVAILABLE -> " + profile?.Franchise?.rtm
                                    : "0 RTM LEFT"}
                                </Col>
                                <Col
                                  xs="12"
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "flex-end",
                                    alignContent: "flex-end",
                                  }}
                                >
                                  {console.log(activePlayer?.currentBid)}
                                  <RoundButton
                                    disabled={
                                      !activePlayer?._id ||
                                      !activePlayer?.currentBid ||
                                      activePlayer?.lastBidBy?.franchise
                                        ?._id === profile?.Franchise?._id ||
                                      profile?.Franchise?.rtm < 1
                                    }
                                    type="button"
                                    onClick={onRTMClick}
                                    className="start-btn"
                                    style={{
                                      backgroundColor:
                                        profile?.Franchise?.rtm > 0
                                          ? "#0BD18A"
                                          : "#920202",
                                      minWidth: "15rem",
                                      borderColor:
                                        profile?.Franchise?.rtm > 0
                                          ? "#0BD18A"
                                          : "#920202",
                                      padding: "0.4rem 1rem",
                                      fontSize: "1.3rem",
                                      margin: "1.5rem 0",
                                      fontFamily: "Poppins-SemiBold",
                                      borderRadius: "2rem",
                                    }}
                                  >
                                    <img
                                      alt="suviyb"
                                      src={gavel2}
                                      style={{
                                        height: "35px",
                                      }}
                                    ></img>
                                    RTM
                                  </RoundButton>
                                </Col>
                              </>
                            ) : (
                              <>
                                {bidSetting?.length ? (
                                  bidSetting.map((item, i) => (
                                    <Col
                                      onClick={() => {
                                        if (activePlayer?.name) {
                                          if (activePlayer.currentBid) {
                                            // if (i === 0) {
                                            //   if (
                                            //     activePlayer.currentBid < 100 &&
                                            //     activePlayer.basePrice < 100
                                            //   ) {
                                            //     setActiveBid(item);
                                            //   }
                                            // } else
                                            setActiveBid(item);
                                          } else
                                            setActiveBid(
                                              activePlayer?.basePrice
                                            );
                                        } else setActiveBid(false);
                                      }}
                                      xs="4"
                                      style={{
                                        marginTop: "1rem",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "flex-end",
                                        alignContent: "flex-end",
                                      }}
                                    >
                                      <BiddingPrice
                                        price={item}
                                        // enabled={(() => {
                                        //   if (activePlayer.currentBid) {
                                        //     if (i === 0) {
                                        //       if (
                                        //         activePlayer.currentBid < 100 &&
                                        //         activePlayer.basePrice < 100
                                        //       ) {
                                        //         return true;
                                        //       }
                                        //       return false;
                                        //     }
                                        //     return true;
                                        //   } else return false;
                                        // })()}
                                        enabled={true}
                                        className={
                                          activeBid === item && "selected"
                                        }
                                      />
                                    </Col>
                                  ))
                                ) : (
                                  <Col
                                    xs="4"
                                    style={{
                                      marginTop: "1rem",
                                      display: "flex",
                                      width: "100%",
                                      padding: 0,
                                      justifyContent: "center",
                                      alignItems: "flex-end",
                                      alignContent: "flex-end",
                                    }}
                                  >
                                    <button
                                      style={{
                                        width: "100%",
                                        color: "grey",
                                        outline: "grey",
                                        border: "none",
                                        padding: "1rem",
                                        fontSize: "1.7rem",
                                      }}
                                      className={`bidding-amount`}
                                    >
                                      <span>Player Loading</span>
                                    </button>
                                  </Col>
                                )}
                                <Col
                                  xs="12"
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "flex-end",
                                    alignContent: "flex-end",
                                  }}
                                >
                                  <RoundButton
                                    disabled={(() => {
                                      console.log(activeBid);
                                      if (!activeBid) {
                                        return true;
                                      } else {
                                        return (
                                          !activePlayer?._id || blockBidding
                                        );
                                      }
                                    })()}
                                    type="button"
                                    onClick={onBidClick}
                                    className="start-btn"
                                    style={{
                                      backgroundColor: "#0040A8",
                                      minWidth: "15rem",
                                      borderColor: "#0040A8",
                                      padding: "0.4rem 1rem",
                                      fontSize: "1.3rem",
                                      margin: "1.5rem 0",
                                      fontFamily: "Poppins-SemiBold",
                                      borderRadius: "2rem",
                                    }}
                                  >
                                    <img
                                      alt="suviyb"
                                      src={gavel2}
                                      style={{
                                        height: "35px",
                                      }}
                                    ></img>
                                    BID
                                  </RoundButton>
                                </Col>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {stopBidding ? (
                              <>
                                {bidSetting?.length ? (
                                  bidSetting.map((item, i) => (
                                    <Col
                                      onClick={() => {
                                        if (activePlayer?.name) {
                                          if (activePlayer.currentBid) {
                                            // if (i === 0) {
                                            //   if (
                                            //     activePlayer.currentBid < 100 &&
                                            //     activePlayer.basePrice < 100
                                            //   ) {
                                            //     setActiveBid(item);
                                            //   }
                                            // } else
                                            setActiveBid(item);
                                          } else
                                            setActiveBid(
                                              activePlayer?.basePrice
                                            );
                                        } else setActiveBid(false);
                                      }}
                                      xs="4"
                                      style={{
                                        marginTop: "1rem",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "flex-end",
                                        alignContent: "flex-end",
                                      }}
                                    >
                                      <BiddingPrice
                                        price={item}
                                        // enabled={(() => {
                                        //   if (activePlayer.currentBid) {
                                        //     if (i === 0) {
                                        //       if (
                                        //         activePlayer.currentBid < 100 &&
                                        //         activePlayer.basePrice < 100
                                        //       ) {
                                        //         return true;
                                        //       }
                                        //       return false;
                                        //     }
                                        //     return true;
                                        //   } else return false;
                                        // })()}
                                        enabled={true}
                                        className={
                                          activeBid === item && "selected"
                                        }
                                      />
                                    </Col>
                                  ))
                                ) : (
                                  <Col
                                    xs="4"
                                    style={{
                                      marginTop: "1rem",
                                      display: "flex",
                                      width: "100%",
                                      padding: 0,
                                      justifyContent: "center",
                                      alignItems: "flex-end",
                                      alignContent: "flex-end",
                                    }}
                                  >
                                    <button
                                      style={{
                                        width: "100%",
                                        color: "grey",
                                        outline: "grey",
                                        border: "none",
                                        padding: "1rem",
                                        fontSize: "1.7rem",
                                      }}
                                      className={`bidding-amount`}
                                    >
                                      <span>Player Loading</span>
                                    </button>
                                  </Col>
                                )}
                                <Col
                                  xs="12"
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "flex-end",
                                    alignContent: "flex-end",
                                  }}
                                >
                                  <RoundButton
                                    disabled={(() => {
                                      console.log(activeBid);
                                      if (!activeBid) {
                                        return true;
                                      } else {
                                        return (
                                          !activePlayer?._id || blockBidding
                                        );
                                      }
                                    })()}
                                    type="button"
                                    onClick={onBidClick}
                                    className="start-btn"
                                    style={{
                                      backgroundColor: "#0040A8",
                                      minWidth: "15rem",
                                      borderColor: "#0040A8",
                                      padding: "0.4rem 1rem",
                                      fontSize: "1.3rem",
                                      margin: "1.5rem 0",
                                      fontFamily: "Poppins-SemiBold",
                                      borderRadius: "2rem",
                                    }}
                                  >
                                    <img
                                      alt="suviyb"
                                      src={gavel2}
                                      style={{
                                        height: "35px",
                                      }}
                                    ></img>
                                    BID
                                  </RoundButton>
                                </Col>
                              </>
                            ) : (
                              <>
                                {bidSetting?.length ? (
                                  bidSetting.map((item, i) => (
                                    <Col
                                      onClick={() => {
                                        if (activePlayer?.name) {
                                          if (activePlayer.currentBid) {
                                            // if (i === 0) {
                                            //   if (
                                            //     activePlayer.currentBid < 100 &&
                                            //     activePlayer.basePrice < 100
                                            //   ) {
                                            //     setActiveBid(item);
                                            //   }
                                            // } else
                                            setActiveBid(item);
                                          } else
                                            setActiveBid(
                                              activePlayer?.basePrice
                                            );
                                        } else setActiveBid(false);
                                      }}
                                      xs="4"
                                      style={{
                                        marginTop: "1rem",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "flex-end",
                                        alignContent: "flex-end",
                                      }}
                                    >
                                      <BiddingPrice
                                        price={item}
                                        // enabled={(() => {
                                        //   if (activePlayer.currentBid) {
                                        //     if (i === 0) {
                                        //       if (
                                        //         activePlayer.currentBid < 100 &&
                                        //         activePlayer.basePrice < 100
                                        //       ) {
                                        //         return true;
                                        //       }
                                        //       return false;
                                        //     }
                                        //     return true;
                                        //   } else return false;
                                        // })()}
                                        enabled={true}
                                        className={
                                          activeBid === item && "selected"
                                        }
                                      />
                                    </Col>
                                  ))
                                ) : (
                                  <Col
                                    xs="4"
                                    style={{
                                      marginTop: "1rem",
                                      display: "flex",
                                      width: "100%",
                                      padding: 0,
                                      justifyContent: "center",
                                      alignItems: "flex-end",
                                      alignContent: "flex-end",
                                    }}
                                  >
                                    <button
                                      style={{
                                        width: "100%",
                                        color: "grey",
                                        outline: "grey",
                                        border: "none",
                                        padding: "1rem",
                                        fontSize: "1.7rem",
                                      }}
                                      className={`bidding-amount`}
                                    >
                                      <span>Player Loading</span>
                                    </button>
                                  </Col>
                                )}
                                <Col
                                  xs="12"
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "flex-end",
                                    alignContent: "flex-end",
                                  }}
                                >
                                  <RoundButton
                                    disabled={(() => {
                                      console.log(activeBid);
                                      if (!activeBid) {
                                        return true;
                                      } else {
                                        return (
                                          !activePlayer?._id || blockBidding
                                        );
                                      }
                                    })()}
                                    type="button"
                                    onClick={onBidClick}
                                    className="start-btn"
                                    style={{
                                      backgroundColor: "#0040A8",
                                      minWidth: "15rem",
                                      borderColor: "#0040A8",
                                      padding: "0.4rem 1rem",
                                      fontSize: "1.3rem",
                                      margin: "1.5rem 0",
                                      fontFamily: "Poppins-SemiBold",
                                      borderRadius: "2rem",
                                    }}
                                  >
                                    <img
                                      alt="suviyb"
                                      src={gavel2}
                                      style={{
                                        height: "35px",
                                      }}
                                    ></img>
                                    BID
                                  </RoundButton>
                                </Col>
                              </>
                            )}
                          </>
                        )}
                        {!(RTM && stopBidding) ? <></> : <></>}

                        {RTM && stopBidding ? <></> : <></>}
                      </Row>
                    </>
                  )}
                </>
              ) : (
                <>
                  <Row>
                    <Col
                      xs="12"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "flex-end",
                        alignContent: "flex-end",
                      }}
                    >
                      <RoundButton
                        disabled
                        type="button"
                        className="start-btn"
                        style={{
                          backgroundColor: "#0040A8",
                          minWidth: "15rem",
                          borderColor: "#0040A8",
                          padding: "0.4rem 1rem",
                          fontSize: "1.3rem",
                          margin: "1.5rem 0",
                          fontFamily: "Poppins-SemiBold",
                          borderRadius: "2rem",
                        }}
                      >
                        <img
                          alt="suviyb"
                          src={gavel2}
                          style={{
                            height: "35px",
                          }}
                        ></img>
                        BIDDING
                      </RoundButton>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      xs="12"
                      style={{
                        marginTop: "4rem",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "flex-end",
                        alignContent: "flex-end",
                      }}
                    >
                      <div
                        disabled
                        type="button"
                        className="start-btn"
                        style={{
                          color: "white",
                          backgroundColor: "#0040A8",
                          minWidth: "15rem",
                          borderColor: "#0040A8",
                          padding: "0.4rem 1rem",
                          fontSize: "1.3rem",
                          margin: "1.5rem 0",
                          fontFamily: "Poppins-SemiBold",
                          borderRadius: "2rem",
                        }}
                      >
                        NOT STARTED/STOPPED
                      </div>
                    </Col>
                  </Row>
                </>
              )}
            </Container>
          </div>
        </>
      )}
    </>
  );
};

export default BiddingPage;
