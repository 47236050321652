import "./index.scss";
import background1 from "../resources/images/background.png";
import Navbar from "../components/navbar";
import { Col, Container, Row, Table } from "react-bootstrap";
import LastSoldPlayer from "../components/lastSoldPlayer";
import MostExpPlayer from "../components/mostExpPlayer";
import PurseRemaining from "../components/purseRemaining";
import PlayerDetails from "../components/playerDetails";
import PlayerBid from "../components/playerBid";
import TeamBidding from "../components/teamBidding";
import gavel from "../resources/images/gavel.png";
import gavel2 from "../resources/images/gavel2.png";
import depressed from "../resources/images/depressed.png";
import { useDispatch, useSelector } from "react-redux";
import { selectShowLoading } from "../store/features/auctionSlice";
import Modal from "../components/modal";
import {
  activeBidding,
  blockStatus,
  lastSoldPlayer,
  mostExpPlayer,
  purseremaining,
  selectactiveBidding,
  selectBlockStatus,
  selectLastSoldPlayer,
  selectmostExpPlayer,
  selectPurseRemaining,
  selectstatus,
} from "../store/features/generalSlice";
import React, { useEffect, useState } from "react";
import Video from "../components/video";
import Facecam from "../components/Facecam";
import InfoLine from "../components/Marquee";
import { useParams } from "react-router";
import AnimateOnChange from "react-animate-on-change";

function useInterval(callback, delay) {
  const intervalRef = React.useRef();
  const callbackRef = React.useRef(callback);

  // Remember the latest callback:
  //
  // Without this, if you change the callback, when setInterval ticks again, it
  // will still call your old callback.
  //
  // If you add `callback` to useEffect's deps, it will work fine but the
  // interval will be reset.

  React.useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  // Set up the interval:

  React.useEffect(() => {
    if (typeof delay === "number") {
      intervalRef.current = window.setInterval(
        () => callbackRef.current(),
        delay
      );

      // Clear interval if the components is unmounted or the delay changes:
      return () => window.clearInterval(intervalRef.current);
    }
  }, [delay]);

  // Returns a ref to the interval ID in case you want to clear it manually:
  return intervalRef;
}

function Index({ socket }) {
  let { sessionId } = useParams();
  let [showLoading, setShowLoading] = useState(false);
  let [purseChunks, setPurseChunks] = useState([]);
  let [activePurse, setActivePurse] = useState(0);
  let [animatePurse, setAnimatePurse] = useState(false);
  let [animateSockets, setAnimateSockets] = useState({ bid: false });
  let [playVideo, setPlayVideo] = useState(false);
  let [counter, setCounter] = useState(1);
  let [hide, setHide] = useState({
    purse: false,
    LastSoldPlayer: false,
    currentPlayer: false,
    MostExpPlayer: false,
    lastBidBy: false,
  });
  let [content, setContent] = useState("PLEASE WAIT...");
  let dispatch = useDispatch();

  let status = useSelector(selectstatus);
  let activePlayerData = useSelector(selectactiveBidding);
  let blockActiveStatus = useSelector(selectBlockStatus);
  let lsPlayer = useSelector(selectLastSoldPlayer);
  let MExpPlayer = useSelector(selectmostExpPlayer);
  let purseRemaining = useSelector(selectPurseRemaining);
  // status = "waiting"

  let [src, setSrc] = useState(`${activePlayerData?.image}`);
  let img = new Image();
  img.onerror = function () {
    setSrc("/players/genericplayer.png");
  };
  img.src = src;

  let [MExpSrc, setMExpSrc] = useState(`${MExpPlayer?.image}`);
  let img2 = new Image();
  img2.onerror = function () {
    setMExpSrc("/players/genericplayer.png");
  };
  img2.src = MExpSrc;

  let [LSSrc, setLSSrc] = useState(`${lsPlayer?.image}`);
  let img3 = new Image();
  img3.onerror = function () {
    setLSSrc("/players/genericplayer.png");
  };
  img3.src = LSSrc;

  useEffect(() => {
    switch (blockActiveStatus) {
      case "started":
        setContent("PLEASE WAIT...");
        setShowLoading(false);
        break;
      case "stopped":
        setContent("PLEASE WAIT TILL AUCTION IS STARTED BY CURATOR AGAIN");
        setShowLoading(true);
        break;
      case "ready":
        setContent("PLEASE WAIT TILL CURATOR IS SELECTING A NEW CATEGORY");
        setShowLoading(false);
        break;
      default:
        setContent("PLEASE WAIT...");
        setShowLoading(false);
    }
  }, [blockActiveStatus]);

  useEffect(() => {
    let h = {
      purse: true,
      LastSoldPlayer: true,
      currentPlayer: true,
      MostExpPlayer: true,
      lastBidBy: true,
    };

    if (status === "waiting") {
      h = {
        purse: true,
        LastSoldPlayer: true,
        currentPlayer: true,
        MostExpPlayer: true,
        lastBidBy: true,
      };
    }

    if (activePlayerData?.name) {
      h.currentPlayer = false;
    }

    if (purseRemaining[0]) {
      h.purse = false;
    }

    if (lsPlayer) {
      h.LastSoldPlayer = false;
    }

    if (MExpPlayer) {
      h.MostExpPlayer = false;
    }

    if (activePlayerData?.lastBidBy?.team) {
      h.lastBidBy = false;
    }

    // console.log(h);

    setHide(h);
  }, [status, activePlayerData?.name, counter]);

  console.log(activePlayerData);

  useEffect(() => {
    dispatch(activeBidding(sessionId));
    dispatch(mostExpPlayer(sessionId));
    dispatch(purseremaining(sessionId));
    dispatch(lastSoldPlayer(sessionId));
    dispatch(blockStatus(sessionId));
  }, [counter]);

  useEffect(() => {
    setSrc(`${activePlayerData?.image}`);
  }, [activePlayerData]);

  useEffect(() => {
    dispatch(activeBidding(sessionId));
    dispatch(mostExpPlayer(sessionId));
    dispatch(purseremaining(sessionId));
    dispatch(lastSoldPlayer(sessionId));
    dispatch(blockStatus(sessionId));
  }, []);

  useEffect(() => {
    socket.emit("public-entry");

    socket.on("connected", () => {
      socket.emit("join-session", sessionId);
    });

    socket.on("block-started", (playerType) => {
      setShowLoading(true);
      setContent(playerType?.toUpperCase() + " BLOCK STARTED!");
      setTimeout(() => {
        setShowLoading(false);
        setContent("PLEASE WAIT... WHILE PLAYER IS BEING SELECTED");
      }, 3000);
    });

    socket.on("block-paused", () => {
      dispatch(activeBidding(sessionId));
      dispatch(blockStatus(sessionId));
      // window.location.reload();
    });

    socket.on("block-finished", (playerType) => {
      dispatch(mostExpPlayer(sessionId));
      dispatch(lastSoldPlayer());

      setShowLoading(true);
      // console.log(playerType);
      setContent(playerType?.toUpperCase() + " BLOCK FINISHED!");

      dispatch(activeBidding(sessionId));
      dispatch(blockStatus(sessionId));
      setCounter((a) => a + 1);

      setTimeout(() => {
        setShowLoading(false);

        // window.location.reload();
      }, 3000);
    });

    socket.on("bid-updated", () => {
      dispatch(mostExpPlayer(sessionId));
      dispatch(lastSoldPlayer(sessionId));

      dispatch(activeBidding(sessionId));
      dispatch(blockStatus(sessionId));
      setDemoBid();
      setCounter((a) => a + 1);
    });

    socket.on("set-bid-reset", () => {
      dispatch(mostExpPlayer(sessionId));
      dispatch(lastSoldPlayer(sessionId));

      setContent(
        <>
          {/* <img src={depressed} style={{ height: "13rem" }} /> */}
          <span
            style={{
              color: "inherit",
              fontSize: "2rem",
            }}
          >
            {" "}
            UPDATING DATA...
          </span>
        </>
      );

      setShowLoading(true);

      // setContent();
      setTimeout(() => {
        setShowLoading(false);
        // window.location.reload();
      }, 1000);

      setCounter((a) => a + 1);
    });

    socket.on("rtm-updated", () => {
      dispatch(activeBidding(sessionId));
      dispatch(blockStatus(sessionId));
      setContent(
        <>
          {/* <img src={depressed} style={{ height: "13rem" }} /> */}
          <span
            style={{
              color: "#0BD18A",
              fontSize: "2rem",
            }}
          >
            {" "}
            RTM USED!
          </span>
        </>
      );
      setShowLoading(true);

      setTimeout(() => {
        setShowLoading(false);
        // window.location.reload();
      }, 3000);
      setCounter((a) => a + 1);
    });

    socket.on("set-player-unsold", (player) => {
      setContent(
        <div
          style={{
            flexDirection: "column",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          {/* <img
            alt="player"
            src={src}
            style={{
              height: "13rem",
              filter: "grayscale(.7)",
            }}
          /> */}
          <span
            style={{
              color: "#FF1010",
              fontFamily: "Barlow-Bold",
              borderRadius: "0.6875rem",
              border: "1.131px solid rgba(185, 0, 0, 0.72)",
              background: "rgba(185, 0, 0, 0.15)",
              fontSize: "1.2rem",
              padding: ".3rem .7rem",
            }}
          >
            {" "}
            TEAMS NE AUKAAT DIKHA DI
          </span>
          <span
            style={{
              color: "#FFF",
              marginTop: ".7rem",
              fontFamily: "Barlow-Bold",
              borderRadius: "1.2rem",
              background: "#B90000",
              border: "1.131px solid rgba(185, 0, 0, 0.72)",
              fontSize: "1.2rem",
              padding: ".7rem 1.6rem",
            }}
          >
            {" "}
            UNSOLD
          </span>
        </div>
      );
      setShowLoading(true);
      dispatch(activeBidding(sessionId));
      dispatch(blockStatus(sessionId));
      setCounter((a) => a + 1);
      setTimeout(() => {
        setShowLoading(false);
        // window.location.reload();
      }, 3000);
    });

    socket.on("set-player-sold", (player) => {
      dispatch(activeBidding(sessionId));
      dispatch(blockStatus(sessionId));
      dispatch(mostExpPlayer(sessionId));
      dispatch(lastSoldPlayer(sessionId));

      setCounter((a) => a + 1);
      setShowLoading(true);
      setContent(
        <>
          <div
            style={{
              flexDirection: "column",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              position: "relative",
              alignItems: "center",
            }}
          >
            {/* <img
              alt="player"
              src={player?.image}
              style={{
                height: "13rem",
              }}
            /> */}
            <span
              style={{
                position: "absolute",
                textAlign: "left",
                color: "#00FF85",
                bottom: "4.9rem",
                left: "4px",
                fontFamily: "Barlow-Bold",
                borderRadius: "0.6875rem",
                border: "1.131px solid rgba(0, 178, 71, 0.72)",
                background: "rgba(0, 178, 71, 0.50)",
                fontSize: "1.2rem",
                padding: ".3rem .7rem",
              }}
            >
              {" "}
              {player?.currentBid > 100
                ? player?.currentBid / 100 + " CR"
                : player?.currentBid > 100 + " L"}
            </span>
            <img
              style={{
                bottom: "3.5rem",
                right: 0,
                height: "6rem",
                position: "absolute",
              }}
              src={`${player?.lastBidBy?.logo}`}
            />
            <span
              style={{
                color: "#FFF",
                fontFamily: "Barlow-Medium",
                borderRadius: "1.2rem",
                textAlign: "left",
                display: "flex",
                background: "#0CCA6F",
                border: "1.131px solid rgba(185, 0, 0, 0.72)",
                fontSize: "1.2rem",
                padding: ".5rem 2.7rem",
              }}
            >
              <img
                style={{ paddingRight: "1rem" }}
                src={"/logos/hammer-1.png"}
              />
              <div>
                {" "}
                SOLD
                <div
                  style={{
                    color: "#FFF",
                    fontFamily: "Barlow-extrabold",
                    fontSize: "1.8rem",
                  }}
                >
                  {player?.lastBidBy?.team?.split(" ")[0]}
                </div>
              </div>
            </span>
          </div>
        </>
      );

      setTimeout(() => {
        setShowLoading(false);
        // window.location.reload();
      }, 5000);
    });

    socket.on("bhide-falls-play", () => {
      setPlayVideo(true);
    });
  }, []);

  useEffect(() => {
    const perChunk = 2; // items per chunk

    if (purseRemaining[0]) {
      setPurseChunks(
        purseRemaining?.reduce((resultArray, item, index) => {
          const chunkIndex = Math.floor(index / perChunk);

          if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = []; // start a new chunk
          }

          resultArray[chunkIndex].push(item);

          return resultArray;
        }, [])
      );
    }
  }, [purseRemaining]);

  const nextPurseQueue = () => {
    if (activePurse < purseChunks?.length - 1) {
      setAnimatePurse(true);
      setActivePurse(activePurse + 1);
    } else {
      setAnimatePurse(true);
      setActivePurse(0);
      // setAnimatePurse(false);
    }
    window.setTimeout(() => {
      setAnimatePurse(false);
    }, 500);
  };

  const setDemoBid = () => {
    setAnimateSockets((prev) => ({ ...prev, bid: true }));
    window.setTimeout(() => {
      setAnimateSockets((prev) => ({ ...prev, bid: false }));
    }, 400);
  };

  useInterval(() => {
    if (purseChunks[0]) {
      nextPurseQueue();
    }
  }, 4000);

  let name = activePlayerData?.name?.split(" ");
  let initial = name && name[0][0];
  let lastName = name?.slice(1, name.length)?.join(" ");

  const animationKeyFrames = `
  @keyframes zigzagsubtle {
   0%   {transform: rotate3d(0, 0, 1, 0deg);}
  50%  {transform: rotate3d(0, 0, 1, 13deg);}
  100% {transform: rotate3d(0, 0, 1, 0deg);}
}
  @keyframes HighlightScale {
   0%   {transform: scale(1) translateY(0rem);}
  50%  {transform: scale(1.1) translateY(-0.2rem);}
  100% {transform: scale(1) translateY(0rem);}
}

@keyframes hide-player-name {
   0%   {transform: translateY(0rem);}
  100% {transform: translateY(4rem);}
}

@keyframes show-player-name {
  0% {transform: translateY(4rem);}
  100%   {transform: translateY(0rem);}
}

@keyframes show-player-detail {
  0% {opacity: 0; transform:scale(0)}
  100% {opacity: 1; transform:scale(1)}
}

@keyframes hide-player-detail {
  0% {opacity: 1; transform:scale(1)}
  100% {opacity: 0; transform:scale(0)}
}


@keyframes fade-element {
  from {opacity: 0.01}
  to {opacity: 1}
}


@keyframes player-face-in {
  0% {opacity: 0; transform:scale(0);}
  100% {opacity: 1; transform:scale(1);}
}

@keyframes player-face-out {
  0% {opacity: 1; transform:scale(1)}
  100% {opacity: 0; transform:scale(0)}
}

@keyframes team-bid-in {
  0% {opacity: 0; transform:scale(0)}
  100% {opacity: 1; transform:scale(1)}
}

@keyframes team-bid-out {
  0% {opacity: 1; transform:scale(1)}
  100% {opacity: 0; transform:scale(0)}
}


@keyframes team-name-in {
  0% {clip-path: inset(0 0 100% 0)}
  100% {clip-path: inset(0 0 0 0)}
}


@keyframes team-name-change {
  0% {clip-path: inset(0 0 100% 0)}
  50% {clip-path: inset(0 0 0 0)}
  100% {clip-path: inset(0 0 100% 0)}
}

@keyframes team-name-out {
  0% {clip-path: inset(0 0 0 0)}
  100% {clip-path: inset(0 0 100% 0)}
}


@keyframes team-logo-in {
  0% {transform:scale(0)}
  100% {transform:scale(1)}
}

@keyframes team-logo-change {
  0% {transform:scale(1)}
  50% {transform:scale(0)}
  100% {transform:scale(1)}
}

@keyframes team-logo-out {
  0% {transform:scale(1)}
  100% {transform:scale(0)}
}

.fade-in-out {
  animation: fade-element .5s ease;
}

.bid {
  animation: HighlightScale .4s ease;
}

`;

  return (
    <>
      <Modal
        show={showLoading}
        spanStyle={{ padding: "1rem 4rem", marginTop: "12rem" }}
        content={content}
      />
      {/* <Video play={playVideo} setPlayVideo={setPlayVideo} /> */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
        }}
      >
        {/* <Navbar /> */}
        <style>{animationKeyFrames}</style>
        <Container
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            width: "100%",
            justifyContent: "space-between",
            maxWidth: "100%",
            background: `url(${background1}) center center/cover`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center top",
            padding: "3rem 5rem 0rem",
          }}
        >
          <Row
            className="top"
            style={{ justifyContent: "center", maxHeight: "17rem" }}
          >
            <Col lg="5" className="left">
              {MExpPlayer?.name && (
                <Row
                  className="most-exp-player"
                  style={{
                    animation: `${
                      MExpPlayer?.name
                        ? "show-player-detail"
                        : "hide-player-detail"
                    } 0.5s ease forwards`,
                  }}
                >
                  <div
                    style={{
                      maxHeight: "7rem",
                      display: "flex",
                      fontFamily: "Barlow-Regular",
                      position: "relative",
                      justifyContent: "flex-start",
                      borderRadius: "1.5625rem",
                    }}
                  >
                    <img
                      style={{
                        position: "absolute",
                        width: "3.6rem",
                        zIndex: 99,
                        top: "-2rem",
                        left: "-1rem",
                      }}
                      src={`${MExpPlayer?.lastBidBy?.franchise?.image}`}
                    />
                    <div
                      style={{
                        display: "flex",
                        minWidth: "24rem",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        height: "100%",
                        backdropFilter: "blur(40px)",
                        padding: "0px 1.5rem",
                        border: "2px solid #FE222B80",
                        borderColor: MExpPlayer?.lastBidBy?.franchise?.color
                          ?.hex
                          ? `${MExpPlayer?.lastBidBy?.franchise?.color?.hex}80`
                          : "#FE222B80",
                        borderRadius: ".6rem",
                        background:
                          "linear-gradient(90deg, rgba(73, 7, 9, 0.15) -1.52%, " +
                          (MExpPlayer?.lastBidBy?.franchise?.color?.hex
                            ? `${MExpPlayer?.lastBidBy?.franchise?.color?.hex}80`
                            : "rgba(254, 34, 43, 0.15)") +
                          " 9.59%)",
                      }}
                    >
                      <div
                        style={{
                          height: "3.1rem",
                          position: "relative",
                          width: "3.1rem",
                          marginRight: "1rem",
                          borderRadius: "50%",
                          backgroundColor:
                            MExpPlayer?.lastBidBy?.franchise?.secondaryColor
                              ?.hex || "#FE222B",
                        }}
                      >
                        <img
                          alt="player"
                          src={`${MExpPlayer?.image}`}
                          style={{
                            zIndex: 999,
                            position: "absolute",
                            top: "2px",
                            left: "-17px",
                            height: "4.1rem",
                            clipPath: "circle(40% at 63% 33%)",
                          }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <span
                          style={{
                            height: "1.5rem",
                            color: "#FFF",
                            fontFeatureSettings: "'clig' off, 'liga' off",
                            fontFamily: "Barlow-Light",
                            fontSize: "1rem",
                            wordSpacing: "2px",
                            fontStyle: "normal",
                            opacity: "0.7",
                            fontWeight: "600",
                            lineHeight: "2.88rem",
                          }}
                        >
                          MOST EXPENSIVE
                        </span>
                        <span
                          style={{
                            color: "#FFF",
                            fontFeatureSettings: "'clig' off, 'liga' off",
                            fontFamily: "Barlow-Bold",
                            fontSize: "1.2rem",
                            wordSpacing: "1px",
                            fontStyle: "normal",
                            fontWeight: "600",
                            lineHeight: "2.88rem",
                          }}
                        >
                          {MExpPlayer?.name
                            ? `${
                                MExpPlayer?.name?.split(" ")[0][0]
                              }. ${MExpPlayer?.name
                                ?.split(" ")
                                ?.slice(
                                  1,
                                  MExpPlayer?.name?.length
                                )}`.toUpperCase()
                            : "-"}
                          <span
                            style={{
                              height: "2rem",
                              color: "#FFF",
                              marginLeft: ".7rem",
                              fontFeatureSettings: "'clig' off, 'liga' off",
                              fontFamily: "Barlow-Light",
                              fontSize: "1rem",
                              wordSpacing: "2px",
                              fontStyle: "normal",
                              opacity: "0.7",
                              fontWeight: "600",
                              lineHeight: "2.88rem",
                            }}
                          >
                            {MExpPlayer?.lastBidBy?.franchise?.name
                              ?.split(" ")?.[0]
                              ?.toUpperCase() || "-"}
                          </span>
                        </span>
                        {/* <img
                    alt="player"
                    src={"/players/viratkohli.png"}
                    style={{
                      maxHeight: "16rem",
                    }}
                  /> */}
                      </div>
                      <div
                        style={{
                          display: "flex",

                          flexDirection: "column",
                          justifyContent: "center",
                          fontFamily: "Barlow-Bold",
                          borderRadius: "0.6875rem",
                          marginLeft: "1rem",
                          padding: ".2rem .6rem",
                          border: "1.131px solid rgba(0, 178, 71, 0.72)",
                          background: "rgba(0, 178, 71, 0.15)",
                          fontSize: "1.2rem",
                          position: "relative",
                          color: "#00FF85",
                          fontFeatureSettings: "'clig' off, 'liga' off",
                        }}
                      >
                        {MExpPlayer?.soldAt > 100
                          ? `${MExpPlayer?.soldAt / 100} CR`
                          : `${MExpPlayer?.soldAt} L`}
                      </div>
                    </div>

                    {/* <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    borderRadius: "1.5625rem",
                    background:
                      "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 33.5%, rgba(23, 23, 23, 0.82) 83.33%)",
                  }} 
                />*/}
                  </div>

                  {/* <MostExpPlayer player={MExpPlayer} hide={hide?.MostExpPlayer} /> */}
                </Row>
              )}
              {lsPlayer?.name && (
                <Row className="last-sold-player">
                  <div
                    style={{
                      marginTop: "2rem",

                      animation: `${
                        lsPlayer?.name
                          ? "show-player-detail"
                          : "hide-player-detail"
                      } 0.5s ease forwards`,
                      maxHeight: "7rem",
                      display: "flex",
                      fontFamily: "Barlow-Regular",
                      position: "relative",
                      justifyContent: "flex-start",
                      borderRadius: "1.5625rem",
                    }}
                  >
                    <img
                      style={{
                        position: "absolute",
                        width: "3.6rem",
                        zIndex: 99,
                        top: "-2rem",
                        left: "-1rem",
                      }}
                      src={`${lsPlayer?.lastBidBy?.franchise?.image}`}
                    />
                    <div
                      style={{
                        display: "flex",
                        minWidth: "24rem",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        height: "100%",
                        backdropFilter: "blur(40px)",
                        padding: "0px 1.5rem",
                        border: "2px solid #FE222B80",
                        borderColor: lsPlayer?.lastBidBy?.franchise?.color?.hex
                          ? `${lsPlayer?.lastBidBy?.franchise?.color?.hex}`
                          : "#FE222B80",
                        borderRadius: ".6rem",
                        background:
                          "linear-gradient(90deg, rgba(73, 7, 9, 0.15) -1.52%, " +
                          lsPlayer?.lastBidBy?.franchise?.color?.hex
                            ? `${lsPlayer?.lastBidBy?.franchise?.color?.hex}80`
                            : "rgba(254, 34, 43, 0.15)" + " 9.59%)",
                      }}
                    >
                      <div
                        style={{
                          height: "3.1rem",
                          position: "relative",
                          width: "3.1rem",
                          marginRight: "1rem",
                          borderRadius: "50%",
                          backgroundColor:
                            lsPlayer?.lastBidBy?.franchise?.secondaryColor
                              ?.hex || "#FE222B",
                        }}
                      >
                        <img
                          alt="player"
                          src={`${lsPlayer?.image}`}
                          style={{
                            zIndex: 999,
                            position: "absolute",
                            top: "3px",
                            left: "-17px",
                            height: "4.1rem",
                            clipPath: "circle(40% at 63% 33%)",
                          }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <span
                          style={{
                            height: "1.5rem",
                            color: "#FFF",
                            fontFeatureSettings: "'clig' off, 'liga' off",
                            fontFamily: "Barlow-Light",
                            fontSize: "1rem",
                            wordSpacing: "2px",
                            fontStyle: "normal",
                            opacity: "0.7",
                            fontWeight: "600",
                            lineHeight: "2.88rem",
                          }}
                        >
                          LAST SOLD
                        </span>
                        <span
                          style={{
                            color: "#FFF",
                            fontFeatureSettings: "'clig' off, 'liga' off",
                            fontFamily: "Barlow-Bold",
                            fontSize: "1.2rem",
                            wordSpacing: "1px",
                            fontStyle: "normal",
                            fontWeight: "600",
                            lineHeight: "2.88rem",
                          }}
                        >
                          {lsPlayer?.name
                            ? `${
                                lsPlayer?.name?.split(" ")[0][0]
                              }. ${lsPlayer?.name
                                ?.split(" ")
                                ?.slice(
                                  1,
                                  lsPlayer?.name?.length
                                )}`.toUpperCase()
                            : "-"}
                          <span
                            style={{
                              height: "2rem",
                              color: "#FFF",
                              marginLeft: ".7rem",
                              fontFeatureSettings: "'clig' off, 'liga' off",
                              fontFamily: "Barlow-Light",
                              fontSize: "1rem",
                              wordSpacing: "2px",
                              fontStyle: "normal",
                              opacity: "0.7",
                              fontWeight: "600",
                              lineHeight: "2.88rem",
                            }}
                          >
                            {lsPlayer?.lastBidBy?.franchise?.name
                              ?.split(" ")?.[0]
                              ?.toUpperCase() || "-"}
                          </span>
                        </span>
                        {/* <img
                    alt="player"
                    src={"/players/viratkohli.png"}
                    style={{
                      maxHeight: "16rem",
                    }}
                  /> */}
                      </div>
                      <div
                        style={{
                          display: "flex",

                          flexDirection: "column",
                          justifyContent: "center",
                          fontFamily: "Barlow-Bold",
                          borderRadius: "0.6875rem",
                          marginLeft: "1rem",
                          padding: ".2rem .6rem",
                          border: "1.131px solid rgba(0, 178, 71, 0.72)",
                          background: "rgba(0, 178, 71, 0.15)",
                          fontSize: "1.2rem",
                          position: "relative",
                          color: "#00FF85",
                          fontFeatureSettings: "'clig' off, 'liga' off",
                        }}
                      >
                        {lsPlayer?.soldAt > 100
                          ? `${lsPlayer?.soldAt / 100} CR`
                          : `${lsPlayer?.soldAt} L`}
                      </div>
                    </div>

                    {/* <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    borderRadius: "1.5625rem",
                    background:
                      "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 33.5%, rgba(23, 23, 23, 0.82) 83.33%)",
                  }} 
                />*/}
                  </div>

                  {/* <LastSoldPlayer player={lsPlayer} hide={hide?.LastSoldPlayer} /> */}
                </Row>
              )}
            </Col>
            <Col
              style={{
                display: "flex",
                justifyContent: "center",
              }}
              lg="3"
              className="middle"
            >
              <Facecam player={activePlayerData} />
            </Col>
            <Col
              lg="4"
              style={{ display: "flex", justifyContent: "flex-end" }}
              className="right"
            >
              <Row
                className="purse-remaining"
                style={{
                  justifyContent: "flex-end",
                  padding: 0,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    fontFamily: "Barlow-Regular",
                    position: "relative",
                    justifyContent: "flex-start",
                    borderRadius: "1.5625rem",
                  }}
                >
                  <img
                    style={{
                      position: "absolute",
                      width: "3.6rem",
                      zIndex: 99,
                      top: "-2rem",
                      left: "-1rem",
                    }}
                    src={"/logos/purse.png"}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      backdropFilter: "blur(40px)",
                      padding: "0px 1.5rem",
                      border: "2px solid rgba(0, 178, 71, 0.72)",
                      borderRadius: ".6rem",
                      background: "rgba(0, 178, 71, 0.30)",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <span
                        style={{
                          height: "2rem",
                          color: "#FFF",
                          fontFeatureSettings: "'clig' off, 'liga' off",
                          fontFamily: "Barlow-Bold",
                          fontSize: "1.3rem",
                          wordSpacing: "2px",
                          fontStyle: "normal",
                          margin: "1rem auto",
                          opacity: "0.7",
                          fontWeight: "600",
                          lineHeight: "2.88rem",
                        }}
                      >
                        PURSE REMAINING
                      </span>
                      {/* <button onClick={setDemoBid}>test</button> */}
                      {purseChunks[0] &&
                        purseChunks[activePurse]?.map((p) => (
                          <AnimateOnChange
                            animationClassName="fade-in-out"
                            animate={animatePurse}
                            // onAnimationEnd={() => {
                            //   console.log("onAnimationEnd");
                            //   setAnimatePurse(false);
                            // }}
                          >
                            <div
                              style={{
                                padding: ".5rem 0rem",
                                color: "#FFF",
                                fontFeatureSettings: "'clig' off, 'liga' off",
                                fontFamily: "Barlow-Bold",
                                fontSize: "1.2rem",
                                wordSpacing: "1px",
                                // maxHeight: "8rem",
                                display: "flex",
                                fontStyle: "normal",
                                fontWeight: "600",
                                lineHeight: "2.88rem",
                              }}
                            >
                              <img
                                alt=""
                                src={`${p?.image}`}
                                style={{
                                  objectFit: "contain",
                                  width: "7rem",
                                  height: "7rem",
                                }}
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <span
                                  style={{
                                    transition: "all 1s ease",
                                    height: "2rem",
                                    color: "#FFF",
                                    marginLeft: ".7rem",
                                    fontFeatureSettings:
                                      "'clig' off, 'liga' off",
                                    fontFamily: "Barlow-Light",
                                    fontSize: "1rem",
                                    wordSpacing: "2px",
                                    fontStyle: "normal",
                                    opacity: "0.7",
                                    fontWeight: "600",
                                    lineHeight: "2.88rem",
                                  }}
                                >
                                  {p?.name?.split(" ")[0]}
                                </span>{" "}
                                <span
                                  style={{
                                    height: "2rem",
                                    color: "#00FF85",
                                    marginLeft: ".7rem",
                                    fontFeatureSettings:
                                      "'clig' off, 'liga' off",
                                    fontFamily: "Barlow-Bold",
                                    fontSize: "2rem",
                                    wordSpacing: "2px",
                                    lineHeight: "2.88rem",
                                  }}
                                >
                                  {p?.purse > 100
                                    ? `${(p?.purse / 100).toFixed(2)} CR`
                                    : `${(p?.purse / 100).toFixed(2)} L`}
                                </span>
                              </div>
                            </div>
                          </AnimateOnChange>
                        ))}
                    </div>
                  </div>

                  {/* <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    borderRadius: "1.5625rem",
                    background:
                      "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 33.5%, rgba(23, 23, 23, 0.82) 83.33%)",
                  }} 
                />*/}
                </div>
                {/* <PurseRemaining
                  data={purseRemaining}
                  hide={hide?.purse}
                  sessionId={sessionId}
                /> */}
              </Row>
            </Col>
          </Row>
          <Row
            className="bottom"
            style={{
              paddingBottom: "3rem",
              alignItems: "flex-end",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Col
              style={{ display: "flex", justifyContent: "flex-start" }}
              lg="4"
              className="left"
            >
              <div
                style={{
                  display: "flex",
                  fontFamily: "Barlow-Regular",
                  position: "relative",
                  justifyContent: "flex-start",
                  borderRadius: "1.5625rem",
                }}
              >
                <div
                  style={{
                    animation: `${
                      activePlayerData?.name
                        ? "show-player-name"
                        : "hide-player-name"
                    } 1s ease forwards`,
                    position: "absolute",
                    width: "70%",
                    zIndex: 0,
                    fontFamily: "Barlow-Bold",
                    fontSize: "1.5rem",
                    padding: ".25rem 1rem .4rem",
                    color: "#EDEDED",
                    letterSpacing: "1px",
                    textAlign: "center",
                    borderRadius: "0.5rem 0.5rem 0rem 0rem",
                    background: "#1C77FF",
                    top: "-2.7rem",
                  }}
                >
                  {activePlayerData?.name
                    ? `${initial}. ${String(lastName)}`
                    : "-"}
                </div>
                <div
                  style={{
                    display: "flex",
                    minWidth: "24rem",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    backdropFilter: "blur(40px)",
                    padding: "0px 1.5rem",
                    border: "2px solid rgba(255, 255, 255, 0.28)",
                    borderRadius: ".4rem",
                    background:
                      "linear-gradient(90deg, rgba(255, 255, 255, 0.10) -1.52%, rgba(255, 255, 255, 0.02) 104.35%)",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        marginTop: "1rem",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <span
                        style={{
                          color: "#FFF",
                          fontFeatureSettings: "'clig' off, 'liga' off",
                          fontFamily: "Barlow-Light",
                          fontSize: "1rem",
                          wordSpacing: "1rem",
                          fontStyle: "normal",
                          fontWeight: "600",
                          textAlign: "center",
                          opacity: "0.7",
                        }}
                      >
                        ROLE
                      </span>
                      <span
                        style={{
                          color: "#FFF",
                          fontFeatureSettings: "'clig' off, 'liga' off",
                          fontFamily: "Barlow-Bold",
                          fontSize: "1.3rem",
                          textAlign: "center",
                          fontStyle: "normal",
                          fontWeight: "600",
                        }}
                      >
                        {activePlayerData?.role || "-"}
                      </span>
                    </div>
                    <div
                      style={{
                        marginTop: "1rem",
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      {/* <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          margin: "1rem",
                        }}
                      >
                        <span
                          style={{
                            color: "#FFF",
                            fontFeatureSettings: "'clig' off, 'liga' off",
                            fontFamily: "Barlow-Light",
                            fontSize: "1rem",
                            wordSpacing: "1rem",
                            fontStyle: "normal",
                            fontWeight: "600",
                            opacity: "0.7",
                          }}
                        >
                          MENTALITY
                        </span>
                        <span
                          style={{
                            animation: `${
                              activePlayerData?.battingMentality
                                ? "team-name-in"
                                : "team-name-out"
                            } .5s ease forwards`,
                            color: "#FFF",
                            fontFeatureSettings: "'clig' off, 'liga' off",
                            fontFamily: "Barlow-Bold",
                            fontSize: "1.2rem",
                            fontStyle: "normal",
                            fontWeight: "600",
                          }}
                        >
                          <img
                            alt="cricket-bat.png"
                            src="/logos/cricket-bat.png"
                            style={{
                              animation: "zigzagsubtle 5s infinite",
                              marginRight: ".3rem",
                              height: "1.3rem",
                            }}
                          />
                          {activePlayerData?.battingMentality
                            ? activePlayerData?.battingMentality?.split(" ")[0]
                            : "-"}
                        </span>
                        <span
                          style={{
                            animation: `${
                              activePlayerData?.bowlingMentality
                                ? "team-name-in"
                                : "team-name-out"
                            } .5s ease forwards`,
                            color: "#FFF",
                            fontFeatureSettings: "'clig' off, 'liga' off",
                            fontFamily: "Barlow-Bold",
                            fontSize: "1.2rem",
                            wordSpacing: "1rem",
                            fontStyle: "normal",
                            fontWeight: "600",
                          }}
                        >
                          <img
                            alt="cricket-ball.png"
                            src="/logos/cricket-ball.png"
                            style={{
                              animation: "rotation infinite 20s linear",
                              marginRight: ".3rem",
                              height: "1.2rem",
                            }}
                          />
                          {activePlayerData?.bowlingMentality
                            ? activePlayerData?.bowlingMentality?.split(" ")[0]
                            : "-"}
                        </span>
                      </div>{" "} */}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          margin: "1rem",
                        }}
                      >
                        <span
                          style={{
                            color: "#FFF",
                            fontFeatureSettings: "'clig' off, 'liga' off",
                            fontFamily: "Barlow-Light",
                            fontSize: "1rem",
                            wordSpacing: "1rem",
                            fontStyle: "normal",
                            fontWeight: "600",
                            opacity: "0.7",
                          }}
                        >
                          HAND
                        </span>
                        <span
                          style={{
                            animation: `${
                              activePlayerData?.battingHanded
                                ? "team-name-in"
                                : "team-name-out"
                            } .5s ease forwards`,
                            color: "#FFF",
                            fontFeatureSettings: "'clig' off, 'liga' off",
                            fontFamily: "Barlow-Bold",
                            fontSize: "1.2rem",
                            fontStyle: "normal",
                            fontWeight: "600",
                          }}
                        >
                          <img
                            alt="cricket-bat.png"
                            src="/logos/cricket-bat.png"
                            style={{
                              animation: "zigzagsubtle 5s infinite",
                              marginRight: ".3rem",
                              height: "1.3rem",
                            }}
                          />
                          {activePlayerData?.battingHanded
                            ? activePlayerData?.battingHanded
                            : "-"}
                        </span>
                        <span
                          style={{
                            animation: `${
                              activePlayerData?.bowlingHanded
                                ? "team-name-in"
                                : "team-name-out"
                            } .5s ease forwards`,
                            color: "#FFF",
                            fontFeatureSettings: "'clig' off, 'liga' off",
                            fontFamily: "Barlow-Bold",
                            fontSize: "1.2rem",
                            // wordSpacing: "1rem",
                            fontStyle: "normal",
                            fontWeight: "600",
                          }}
                        >
                          <img
                            alt="cricket-ball.png"
                            src="/logos/cricket-ball.png"
                            style={{
                              animation: "rotation infinite 20s linear",

                              marginRight: ".3rem",
                              height: "1.2rem",
                            }}
                          />
                          {activePlayerData?.bowlingHanded
                            ? activePlayerData?.bowlingHanded
                            : "-"}
                        </span>
                      </div>
                    </div>
                    <div
                      style={{
                        margin: "1rem",
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                        }}
                      >
                        <span
                          style={{
                            color: "#FFF",
                            fontFeatureSettings: "'clig' off, 'liga' off",
                            fontFamily: "Barlow-Light",
                            fontSize: "1rem",
                            wordSpacing: ".3rem",
                            fontStyle: "normal",
                            fontWeight: "600",
                            opacity: "0.7",
                          }}
                        >
                          PREVIOUS TEAM
                        </span>
                        <span
                          style={{
                            animation: `${
                              activePlayerData?.name
                                ? "team-name-in"
                                : "team-name-out"
                            } .5s ease forwards`,
                            color: "#FFF",
                            fontFeatureSettings: "'clig' off, 'liga' off",
                            fontFamily: "Barlow-Bold",
                            fontSize: "1.3rem",
                            fontStyle: "normal",
                            fontWeight: "600",
                          }}
                        >
                          {console.log(activePlayerData?.lastYear)}
                          {activePlayerData?.lastYear?.isSold ? (
                            <>
                              {`${
                                activePlayerData?.lastYear?.lastBidBy?.team?.split(
                                  " "
                                )[0]
                              }  `}
                              {/* <img
                                alt="team"
                                src={`${activePlayerData?.lastYear?.lastBidBy?.franchise?.image}`}
                                style={{
                                  marginRight: ".3rem",
                                  height: "4rem",
                                }}
                              /> */}
                              {activePlayerData?.lastYear?.soldAt > 100
                                ? `${
                                    activePlayerData?.lastYear?.soldAt / 100
                                  }CR`
                                : `${activePlayerData?.lastYear?.soldAt}L`}
                            </>
                          ) : (
                            "-"
                          )}
                        </span>
                      </div>{" "}
                      <div
                        style={{
                          borderRadius: ".8rem",
                          border: "2px solid #D9E8FF",
                          flexDirection: "column",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: "0.2rem 2.2rem",
                          height: "fit-content",
                          alignContent: "center",
                        }}
                      >
                        <div
                          style={{
                            fontFamily: "Barlow-Thin",
                            color: "rgba(255, 255, 255, 0.75)",
                            textAlign: "center",
                            fontSize: "1rem",
                            fontStyle: "normal",
                            letterSpacing: "0.03rem",
                            fontWeight: "800",
                            lineHeight: "normal",
                          }}
                        >
                          Base Price
                        </div>
                        <div
                          style={{
                            fontFamily: "Barlow-ExtraBold",
                            color: "#FFF",
                            fontSize: "1.4rem",
                            fontStyle: "normal",
                            letterSpacing: "1px",
                            fontWeight: "800",
                            lineHeight: "normal",
                          }}
                        >
                          {activePlayerData?.basePrice
                            ? `${activePlayerData?.basePrice}L`
                            : "-"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    borderRadius: "1.5625rem",
                    background:
                      "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 33.5%, rgba(23, 23, 23, 0.82) 83.33%)",
                  }} 
                />*/}
              </div>
              {/* <PlayerDetails
                hide={hide?.currentPlayer}
                player={activePlayerData}
              /> */}
            </Col>
            <Col
              style={{
                display: "flex",
                justifyContent: "center",
              }}
              lg="4"
              className="middle"
            >
              <div
                style={{
                  animation: `${
                    activePlayerData?.name
                      ? "player-face-in"
                      : "player-face-out"
                  } 1s ease forwards`,
                  display: "flex",
                  fontFamily: "Barlow-Regular",
                  position: "relative",
                  justifyContent: "center",
                  borderRadius: "1.5625rem",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    zIndex: 99,
                    left: "1.5rem",
                    top: "1rem",
                  }}
                >
                  {(activePlayerData?.nationality?.toLowerCase() === "india" ||
                    activePlayerData?.nationality?.toLowerCase() ===
                      "indian") && (
                    <img
                      alt=""
                      src="/logos/indian.png"
                      style={{ height: "2rem", marginRight: ".3rem" }}
                    />
                  )}
                  {(activePlayerData?.elite || activePlayerData?.marquee) && (
                    <img
                      alt=""
                      src="/logos/elite.png"
                      style={{ height: "2rem" }}
                    />
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    height: "100%",
                    backdropFilter: "blur(40px)",
                    padding: "1rem 3rem 3rem 3rem",
                    border: "4px solid rgba(255, 255, 255, 0.30)",
                    borderRadius: "1.5625rem",
                    background: "rgba(255, 255, 255, 0.10)",
                    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                  }}
                >
                  <img
                    alt="player"
                    src={src}
                    style={{
                      maxHeight: "16rem",
                    }}
                  />
                </div>
                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    borderRadius: "1.5625rem",
                    background:
                      "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 33.5%, rgba(23, 23, 23, 0.82) 83.33%)",
                  }}
                />
                <AnimateOnChange
                  animate={animateSockets?.bid}
                  animationClassName="bid"
                  style={{
                    // animation: "HighlightScale .8s infinite",
                    borderRadius: "1.5rem",
                    border: "3px solid #4D94FF",
                    bottom: "1.6rem",
                    flexDirection: "column",
                    padding: "0.2rem 4rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                    position: "absolute",
                  }}
                >
                  <div
                    style={{
                      fontFamily: "Barlow-Light",
                      color: "rgba(255, 255, 255, 0.75)",
                      textAlign: "center",
                      fontSize: "1.3rem",
                      fontStyle: "normal",
                      letterSpacing: "0.03rem",
                      fontWeight: "800",
                      lineHeight: "normal",
                    }}
                  >
                    Current Bid
                  </div>
                  <div
                    style={{
                      fontFamily: "Barlow-ExtraBold",
                      color: "#FFF",
                      fontSize: "1.8rem",
                      fontStyle: "normal",
                      letterSpacing: "1px",
                      fontWeight: "800",
                      lineHeight: "normal",
                    }}
                  >
                    {activePlayerData?.currentBid
                      ? activePlayerData?.currentBid > 100
                        ? `${activePlayerData.currentBid / 100}CR`
                        : `${activePlayerData.currentBid}L`
                      : "-"}
                  </div>
                </AnimateOnChange>
              </div>
              {/* <PlayerBid player={activePlayerData} /> */}
            </Col>
            <Col
              style={{ display: "flex", justifyContent: "flex-end" }}
              lg="4"
              className="right"
            >
              <div
                style={{
                  maxHeight: "7rem",
                  display: "flex",
                  fontFamily: "Barlow-Regular",
                  position: "relative",
                  justifyContent: "center",
                  borderRadius: "1.5625rem",
                }}
              >
                <div
                  style={{
                    animation: `${
                      !activePlayerData?.lastBidBy?.name
                        ? "team-logo-in"
                        : "team-logo-out"
                    } .5s ease-in-out forwards`,
                    position: "absolute",
                    top: "-13rem",
                  }}
                >
                  <img
                    alt="logos"
                    style={{
                      animation: `${
                        activePlayerData?.lastBidBy?.franchise?.image
                          ? "team-logo-in"
                          : "team-logo-out"
                      } .5s ease-in-out forwards`,
                      width: "9rem",
                    }}
                    src={`${activePlayerData?.lastBidBy?.franchise?.image}`}
                  />
                </div>
                <div
                  style={{
                    flexDirection: "column",
                    display: "flex",
                    minWidth: "20rem",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                    height: "100%",
                    backdropFilter: "blur(40px)",
                    padding: "0 3rem",
                    border: `2px solid ${
                      activePlayerData?.lastBidBy?.franchise?.color?.hex
                        ? `${activePlayerData?.lastBidBy?.franchise?.color?.hex}`
                        : "#0CCA6F80"
                    }`,
                    borderRadius: ".6rem",
                    background: activePlayerData?.lastBidBy?.franchise?.color
                      ?.hex
                      ? `${activePlayerData?.lastBidBy?.franchise?.color?.hex}70`
                      : "rgba(12, 202, 111, 0.29)",
                    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                  }}
                >
                  {" "}
                  <span
                    style={{
                      animation: `${
                        activePlayerData?.lastBidBy?.purse
                          ? "team-logo-in"
                          : "team-logo-out"
                      } .5s ease forwards`,
                      position: "absolute",
                      color: "#0F6",
                      right: "-2px",
                      top: "-4rem",
                      display: "flex",
                      borderRadius: "0.6875rem",
                      border: "1.131px solid rgba(0, 178, 71, 0.72)",
                      background: "rgba(0, 178, 71, 0.15)",
                      fontFeatureSettings: "'clig' off, 'liga' off",
                      fontFamily: "Barlow-Semibold",
                      fontSize: "1.2rem",
                      wordSpacing: "2px",
                      fontStyle: "normal",
                      alignItems: "center",
                      opacity: "0.7",
                      fontWeight: "600",
                      padding: "0.3rem 0.8rem",
                    }}
                  >
                    <img
                      alt="purse"
                      style={{
                        display: "inline",
                        width: "1rem",
                        height: "1rem",
                        marginRight: ".4rem",
                      }}
                      src={"/logos/purse.png"}
                    />
                    {activePlayerData?.lastBidBy?.purse
                      ? activePlayerData?.lastBidBy?.purse > 100
                        ? activePlayerData?.lastBidBy?.purse / 100 + "CR"
                        : activePlayerData?.lastBidBy?.purse + "L"
                      : "-"}
                  </span>
                  <span
                    style={{
                      height: "2rem",
                      color: "#FFF",
                      fontFeatureSettings: "'clig' off, 'liga' off",
                      fontFamily: "Barlow-Light",
                      fontSize: "1.2rem",
                      wordSpacing: "2px",
                      fontStyle: "normal",
                      opacity: "0.7",
                      fontWeight: "600",
                      lineHeight: "2.88rem",
                    }}
                  >
                    BID BY
                  </span>
                  <span
                    style={{
                      animation: `${
                        activePlayerData?.lastBidBy?.franchise?.name
                          ? "team-name-in"
                          : "team-name-out"
                      } .5s ease forwards`,
                      color: "#FFF",
                      fontFeatureSettings: "'clig' off, 'liga' off",
                      fontFamily: "Barlow-Bold",
                      fontSize: "1.5rem",
                      wordSpacing: "1px",
                      fontStyle: "normal",
                      fontWeight: "600",
                      lineHeight: "2.88rem",
                    }}
                  >
                    {activePlayerData?.lastBidBy?.franchise?.name || "-"}
                  </span>
                  {/* <img
                    alt="player"
                    src={"/players/viratkohli.png"}
                    style={{
                      maxHeight: "16rem",
                    }}
                  /> */}
                </div>
                {/* <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    borderRadius: "1.5625rem",
                    background:
                      "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 33.5%, rgba(23, 23, 23, 0.82) 83.33%)",
                  }} 
                />*/}
              </div>
              {/* <TeamBidding hide={hide?.lastBidBy} player={activePlayerData} /> */}
            </Col>
          </Row>
        </Container>
        {/*  */}
        {/* <Navbar /> */}
        {/* <InfoLine /> */}
        {/*  */}
      </div>
    </>
  );
}

export default Index;
