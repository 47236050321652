import React, { Children, useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
} from "react-router-dom";
import Navbar from "./components/navbar";
import { store } from "./store/store";
import { Provider, useDispatch } from "react-redux";
import Index from "./pages";
import "./font.scss";
import CuratorPage from "./pages/curator";
import TeamList from "./pages/teamList";
import PlayerList from "./pages/playerList";
import BiddingPage from "./pages/biddingPage";
import ViewAllPlayers from "./pages/viewAllPlayers";
import PlayerListActive from "./pages/playerListActive";
import UrlLoginBidder from "./pages/urlLoginBidder";
import UrlLoginCurator from "./pages/urlLoginCurator";
import io from "socket.io-client";
import { getProfile as CuratorProfile } from "./store/features/curatorSlice";
import { getProfile as BidderProfile } from "./store/features/bidderSlice";
import AllPlayers from "./pages/biddingPage/allPlayers";
import AllPlayersListGeneral from "./pages/playerListGeneral";
import AdminLogin from "./pages/LoginPages/AdminLogin";
import CuratorLogin from "./pages/LoginPages/CuratorLogin";
import BidderLogin from "./pages/LoginPages/BidderLogin";
import background1 from "./resources/images/backgroundMain.png";
import Cbtn from "./components/commonbutton/cbtn";
import { backendDomain } from "./store/paths";

let socket = io.connect(backendDomain);

const Redirect = (props) => {
  console.log(props.url);
  useEffect(() => {
    window.location.href = props.url;
  }, [props.url]);
  return <h5>Loading</h5>;
};

export default Redirect;

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <div
        className="background-index"
        style={{
          background: `url(${background1}) center center/cover`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center top",
          // backgroundSize: "90rem",
          minHeight: "100vh",
          display: "flex",
          justifyContent: "flex-end",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Cbtn
          style={{
            background: "blue",
            margin: "2rem",
            // marginTop: "17rem",
            width: "15rem",
            fontSize: "2rem",
          }}
          to="/curator/auction"
        >
          {" "}
          CURATOR
        </Cbtn>
        <Cbtn
          style={{
            background: "blue",
            margin: "2rem",
            width: "15rem",
            fontSize: "2rem",
            marginBottom: "9rem",
          }}
          to="/bidder/bidding"
        >
          BIDDER
        </Cbtn>
      </div>
    ),
    // errorElement: <div>wrong page</div>,
  },
  {
    path: "/live/:sessionId",
    element: <Index socket={socket} />,
    // errorElement: <div>wrong page</div>,
  },
  {
    path: "/admin",
    element: <>{<Redirect url="https://auctionadmin.spectors.in" />}</>,
    // errorElement: <div>wrong page</div>,
  },
  {
    path: "/curator/login",
    element: <CuratorLogin socket={socket} />,
    // errorElement: <div>wrong page</div>,
  },
  {
    path: "/bidder/login",
    element: <BidderLogin socket={socket} />,
    // errorElement: <div>wrong page</div>,
  },
  {
    path: "/playerlist",
    element: <AllPlayersListGeneral socket={socket} />,
    // errorElement: <div>wrong page</div>,
  },
  {
    path: "curator/auction",
    element: (
      <>
        <CuratorPage socket={socket} />
        <Outlet />
      </>
    ),
  },
  {
    path: "curator",
    element: (
      <>
        <UrlLoginCurator />
      </>
    ),
  },
  {
    path: "curator/teamlist",
    element: (
      <>
        <TeamList socket={socket} />
        <Outlet />
      </>
    ),
  },
  {
    path: "curator/playerlist",
    element: (
      <>
        <PlayerList socket={socket} />
        <Outlet />
      </>
    ),
  },
  {
    path: "curator/auction/playerlist",
    element: (
      <>
        <PlayerListActive socket={socket} />
        <Outlet />
      </>
    ),
  },
  {
    path: "bidder",
    element: (
      <>
        <UrlLoginBidder />
      </>
    ),
  },
  {
    path: "bidder/bidding",
    element: (
      <>
        <BiddingPage socket={socket} />
        <Outlet />
      </>
    ),
  },
  {
    path: "bidder/teamview",
    element: (
      <>
        <ViewAllPlayers socket={socket} />
        <Outlet />
      </>
    ),
  },
  {
    path: "bidder/allplayers",
    element: (
      <>
        <AllPlayers socket={socket} />
        <Outlet />
      </>
    ),
  },
]);

const App = () => {
  let dispatch = useDispatch();

  let checkLogin = async () => {
    const curatorToken = await localStorage.getItem("curatorToken");
    const bidderToken = await localStorage.getItem("bidderToken");
    if (curatorToken) {
    }
    if (bidderToken) {
    }
  };

  useEffect(() => {
    dispatch(CuratorProfile());
    dispatch(BidderProfile());
  });

  return <RouterProvider router={router} />;
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
