import "./index.scss";

import React, { useState } from "react";
import DialogBox from "../dialogBox";
import { Col, Row } from "react-bootstrap";
// import tendua from "../../resources/images/tendua.png";
import { useSelector } from "react-redux";
import {
  selectIsSold,
  selectLoadingStatus,
  selectSellingStatus,
} from "../../store/features/auctionSlice";

import gavel from "../../resources/images/gavel.png";

const PlayerBid = ({ player }) => {
  console.log(player.image);
  let [src, setSrc] = useState(`${player?.image}`);
  let img = new Image();
  img.src = src;
  img.onerror = function () {
    setSrc("/players/genericplayer.png");
  };

  let isSold = useSelector(selectIsSold);
  let sellingStatus = useSelector(selectSellingStatus);
  let loadingStatus = useSelector(selectLoadingStatus);

  return (
    <>
      <Row className="dialog-box vertical" style={{ position: "relative" }}>
        {!(loadingStatus === "fetching") && (
          <Col
            className="box tenduaImg big"
            style={
              {
                // backgroundImage: `url(${
                //   player.image
                //     ? `${process.env.PUBLIC_URL}../../resources/images/gavel.png`
                //     : ""
                // })`,
              }
            }
          >
            {player?.image && (
              <img
                // src={`/players/${"ashwin.png"}`}
                src={src}
                alt="player"
                style={{
                  // left: "41px",
                  // top: "-109px",
                  // width: "20rem",
                  // position: "absolute",
                  maxHeight: "15rem",
                  minHeight: "10rem",
                }}
              />
            )}
          </Col>
        )}

        {isSold ? (
          <Col className="box white big" style={{ backgroundColor: "#0bd18a" }}>
            <Row className="sold">
              <Col
                lg={5}
                className="gavel"
                style={{ backgroundImage: `url(${gavel})` }}
              >
                {/* <img src={gavel} style={{ height: "6rem" }}></img> */}
              </Col>
              <Col>
                <div className="sold-title">SOLD</div>
                <div className="bid-value white">
                  {player?.currentBid
                    ? player?.currentBid > 100
                      ? `${player.currentBid / 100}CR`
                      : `${player.currentBid}L`
                    : "-"}
                </div>
              </Col>
            </Row>
          </Col>
        ) : (
          <>
            {sellingStatus === "in progress" && (
              <Col className="box white big">
                <div className="current-bid">CURRENT BID</div>
                <div className="bid-value">
                  {player?.currentBid
                    ? player?.currentBid > 100
                      ? `${player.currentBid / 100}CR`
                      : `${player.currentBid}L`
                    : "-"}
                </div>
              </Col>
            )}
            {sellingStatus === "unsold" && (
              <Col className="box white big unsold">
                <div className="unsold">UNSOLD</div>
                {/* <div className="bid-value">₹100</div> */}
              </Col>
            )}
            {(sellingStatus === "no bids" || loadingStatus === "fetching") && (
              <Col className="box white big">
                <div className="current-bid">CURRENT BID</div>
                <div className="bid-value">-</div>
              </Col>
            )}
          </>
        )}
      </Row>
    </>
  );
};

export default PlayerBid;
